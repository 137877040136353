
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#ArticleForm {;
  display: flex;
  align-content: center;

  .form {
    padding: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    min-width: 690px;
    width: 55vw;
    border-right: 1px solid var(--color-Alto);

    .field {
      display: flex;
      align-content: center;

      &_title {
        width: 75px;
        font-size: 14px;
        font-family: NanumSquareOTF_acEB;
        line-height: 42px;
      }

      .wrap_select {
        display: flex;
        align-content: center;
        column-gap: 5px;

        select {
          width: 105px;
        }
      }

      .wrap_media_select {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 570px;
      }

      select.media_select_box {
        width: 536px;
      }

      i {
        cursor: pointer;
      }
    }

    .btns {
      margin-top: 32px;
      display: flex;
      justify-content: center;
      align-content: center;
      column-gap: 14px;

      button {
        width: 130px;
        font-size: 14px;
        font-family: NanumSquareOTF_acB;
        line-height: 43px;
        color: #ffffff;
        border-radius: 4px;
      }

      .btn_save {
        background-color: var(--color-Cornflower-Blue);
      }

      .btn_request {
        background-color: var(--color-Niagara);
      }
    }
  }

  .input.full_size {
    width: 570px;
  }

  .input,
  select {
    padding: 0 10px;
    height: 42px;
    font-size: 14px;
    line-height: 18px;
    border-radius: 4px;
    border: solid 1px var(--color-Alto);
  }

  .input {
    min-width: 200px;
  }

  select {
    min-width: 150px;

    &.media {
      width: 305px;
    }
  }

  .input:read-only,
  select:disabled {
    border: none;
    outline: none;
  }

  select:disabled {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
  }

  .toastui-editor-defaultUI {
    min-width: 654px;
    width: calc(55vw - 40px);
  }

  .toastui-editor-tooltip {
    .text {
      color: #ffffff !important;
    }
  }

  .toastui-editor-popup-body {
    input[type='text'].tui-colorpicker-palette-hex {
      width: 100%;
      height: 32px;
    }
  }
}