/* (주의) css 충돌 이슈로 재정의한 파일입니다. */
.modal-open {
  overflow: hidden;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.modal.fade .modal-dialog {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out, -o-transform 0.3s ease-out;
}
.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}
.modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
.modal-backdrop.fade {
  filter: alpha(opacity=0);
  opacity: 0;
}
.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: 0.5;
}
.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}
.modal-header .close {
  margin-top: -2px;
}
.modal-title {
  margin: 0;
  line-height: 1.42857143;
}
.modal-body {
  position: relative;
  padding: 15px;
}
.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
.modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px;
}
.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
.modal-header:before,
.modal-header:after,
.modal-footer:before,
.modal-footer:after {
  display: table;
  content: " ";
}
.modal-header:after,
.modal-footer:after {
  clear: both;
}
@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}

[class*=" note-icon"]:before,
[class^=note-icon]:before {
  display: inline-block;
  font-style: normal;
  font-size: inherit;
  text-decoration: inherit;
  text-rendering: auto;
  text-transform: none;
  vertical-align: middle;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  speak: none
}

.note-icon-fw {
  text-align: center;
  width: 1.25em
}

.note-icon-border {
  border: .08em solid #eee;
  border-radius: .1em;
  padding: .2em .25em .15em
}

.note-icon-pull-left {
  float: left
}

.note-icon-pull-right {
  float: right
}

.note-icon.note-icon-pull-left {
  margin-right: .3em
}

.note-icon.note-icon-pull-right {
  margin-left: .3em
}

.note-icon-align:before {
  content: ""
}

.note-icon-align-center:before {
  content: ""
}

.note-icon-align-indent:before {
  content: ""
}

.note-icon-align-justify:before {
  content: ""
}

.note-icon-align-left:before {
  content: ""
}

.note-icon-align-outdent:before {
  content: ""
}

.note-icon-align-right:before {
  content: ""
}

.note-icon-arrow-circle-down:before {
  content: ""
}

.note-icon-arrow-circle-left:before {
  content: ""
}

.note-icon-arrow-circle-right:before {
  content: ""
}

.note-icon-arrow-circle-up:before {
  content: ""
}

.note-icon-arrows-alt:before {
  content: ""
}

.note-icon-arrows-h:before {
  content: ""
}

.note-icon-arrows-v:before {
  content: ""
}

.note-icon-bold:before {
  content: ""
}

.note-icon-caret:before {
  content: ""
}

.note-icon-chain-broken:before {
  content: ""
}

.note-icon-circle:before {
  content: ""
}

.note-icon-close:before {
  content: ""
}

.note-icon-code:before {
  content: ""
}

.note-icon-col-after:before {
  content: ""
}

.note-icon-col-before:before {
  content: ""
}

.note-icon-col-remove:before {
  content: ""
}

.note-icon-eraser:before {
  content: ""
}

.note-icon-float-left:before {
  content: ""
}

.note-icon-float-none:before {
  content: ""
}

.note-icon-float-right:before {
  content: ""
}

.note-icon-font:before {
  content: ""
}

.note-icon-frame:before {
  content: ""
}

.note-icon-italic:before {
  content: ""
}

.note-icon-link:before {
  content: ""
}

.note-icon-magic:before {
  content: ""
}

.note-icon-menu-check:before {
  content: ""
}

.note-icon-minus:before {
  content: ""
}

.note-icon-orderedlist:before {
  content: ""
}

.note-icon-pencil:before {
  content: ""
}

.note-icon-picture:before {
  content: ""
}

.note-icon-question:before {
  content: ""
}

.note-icon-redo:before {
  content: ""
}

.note-icon-rollback:before {
  content: ""
}

.note-icon-row-above:before {
  content: ""
}

.note-icon-row-below:before {
  content: ""
}

.note-icon-row-remove:before {
  content: ""
}

.note-icon-special-character:before {
  content: ""
}

.note-icon-square:before {
  content: ""
}

.note-icon-strikethrough:before {
  content: ""
}

.note-icon-subscript:before {
  content: ""
}

.note-icon-summernote:before {
  content: ""
}

.note-icon-superscript:before {
  content: ""
}

.note-icon-table:before {
  content: ""
}

.note-icon-text-height:before {
  content: ""
}

.note-icon-trash:before {
  content: ""
}

.note-icon-underline:before {
  content: ""
}

.note-icon-undo:before {
  content: ""
}

.note-icon-unorderedlist:before {
  content: ""
}

.note-icon-video:before {
  content: ""
}

.note-frame {
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  color: #000;
  border-radius: 4px
}

.note-toolbar {
  padding: 10px 5px;
  color: #333;
  background-color: #f5f5f5;
  border-bottom: 1px solid;
  border-color: #ddd;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px
}

.note-btn-group {
  position: relative;
  display: inline-block;
  margin-right: 8px
}

.note-btn-group>.note-btn-group {
  margin-right: 0
}

.note-btn-group>.note-btn:first-child {
  margin-left: 0
}

.note-btn-group .note-btn+.note-btn,
.note-btn-group .note-btn+.note-btn-group,
.note-btn-group .note-btn-group+.note-btn,
.note-btn-group .note-btn-group+.note-btn-group {
  margin-left: -1px
}

.note-btn-group>.note-btn-group:not(:first-child)>.note-btn,
.note-btn-group>.note-btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.note-btn-group>.note-btn-group:not(:last-child)>.note-btn,
.note-btn-group>.note-btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.note-btn-group.open>.note-dropdown {
  display: block
}

.note-btn {
  display: inline-block;
  font-weight: 400;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  outline: 0;
  color: #333;
  background-color: #fff;
  border: 1px solid #dae0e5;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.4;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.note-btn.focus,
.note-btn:focus,
.note-btn:hover {
  color: #333;
  background-color: #ebebeb;
  border-color: #dae0e5
}

.note-btn.disabled.focus,
.note-btn.disabled:focus,
.note-btn[disabled].focus,
.note-btn[disabled]:focus,
fieldset[disabled] .note-btn.focus,
fieldset[disabled] .note-btn:focus {
  background-color: #fff;
  border-color: #dae0e5
}

.note-btn.active,
.note-btn.focus,
.note-btn:active,
.note-btn:focus,
.note-btn:hover {
  color: #333;
  text-decoration: none;
  border: 1px solid #dae0e5;
  background-color: #ebebeb;
  outline: 0;
  border-radius: 1px
}

.note-btn.active,
.note-btn:active {
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125)
}

.note-btn.disabled,
.note-btn[disabled],
fieldset[disabled] .note-btn {
  cursor: not-allowed;
  -webkit-opacity: .65;
  -khtml-opacity: .65;
  -moz-opacity: .65;
  opacity: .65;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=65);
  filter: alpha(opacity=65);
  box-shadow: none
}

.note-btn>span.note-icon-caret:first-child {
  margin-left: -1px
}

.note-btn>span.note-icon-caret:nth-child(2) {
  padding-left: 3px;
  margin-right: -5px
}

.note-btn-primary {
  background: #fa6362;
  color: #fff
}

.note-btn-primary.focus,
.note-btn-primary:focus,
.note-btn-primary:hover {
  color: #fff;
  text-decoration: none;
  border: 1px solid #dae0e5;
  background-color: #fa6362;
  border-radius: 1px
}

.note-btn-block {
  display: block;
  width: 100%
}

.note-btn-block+.note-btn-block {
  margin-top: 5px
}

input[type=button].note-btn-block,
input[type=reset].note-btn-block,
input[type=submit].note-btn-block {
  width: 100%
}

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none
}

.close {
  float: right;
  font-size: 21px;
  line-height: 1;
  color: #000;
  opacity: .2
}

.close:hover {
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  -ms-filter: alpha(opacity=100);
  filter: alpha(opacity=100);
  opacity: 1
}

.note-dropdown {
  position: relative
}

.note-color .dropdown-toggle {
  width: 30px;
  padding-left: 5px
}

.note-dropdown-menu {
  display: none;
  min-width: 100px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  text-align: left;
  background: #fff;
  border: 1px solid #e2e2e2;
  padding: 5px;
  background-clip: padding-box;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06)
}

.note-dropdown-menu>:last-child {
  margin-right: 0
}

.note-btn-group.open .note-dropdown-menu,
.note-dropdown-item {
  display: block
}

.note-dropdown-item:hover {
  background-color: #ebebeb
}

a.note-dropdown-item,
a.note-dropdown-item:hover {
  margin: 5px 0;
  color: #000;
  text-decoration: none
}

.note-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1050;
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
  display: none
}

.note-modal.open {
  display: block
}

.note-modal-content {
  position: relative;
  width: auto;
  margin: 30px 20px;
  border: 1px solid rgba(0, 0, 0, .2);
  background: #fff;
  background-clip: border-box;
  outline: 0;
  border-radius: 5px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, .5)
}

.note-modal-header {
  padding: 10px 20px;
  border: 1px solid #ededef
}

.note-modal-body {
  position: relative;
  padding: 20px 30px
}

.note-modal-body kbd {
  border-radius: 2px;
  background-color: #000;
  color: #fff;
  padding: 3px 5px;
  font-weight: 700;
  -ms-box-sizing: border-box;
  box-sizing: border-box
}

.note-modal-footer {
  height: 40px;
  padding: 10px;
  text-align: center
}

.note-modal-footer a {
  color: #337ab7;
  text-decoration: none
}

.note-modal-footer a:focus,
.note-modal-footer a:hover {
  color: #23527c;
  text-decoration: underline
}

.note-modal-footer .note-btn {
  float: right
}

.note-modal-title {
  font-size: 20px;
  color: #42515f;
  margin: 0;
  line-height: 1.4
}

.note-modal-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1040;
  background: #000;
  -webkit-opacity: .5;
  -khtml-opacity: .5;
  -moz-opacity: .5;
  opacity: .5;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50);
  filter: alpha(opacity=50);
  display: none
}

.note-modal-backdrop.open {
  display: block
}

@media(min-width:768px) {
  .note-modal-content {
    width: 600px;
    margin: 30px auto
  }
}

@media(min-width:992px) {
  .note-modal-content-large {
    width: 900px
  }
}

.note-modal .note-help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373
}

.note-modal .note-nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none
}

.note-modal .note-nav-link {
  display: block;
  padding: .5rem 1rem;
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects
}

.note-modal .note-nav-link:focus,
.note-modal .note-nav-link:hover {
  color: #0056b3;
  text-decoration: none
}

.note-modal .note-nav-link.disabled {
  color: #868e96
}

.note-modal .note-nav-tabs {
  border-bottom: 1px solid #ddd
}

.note-modal .note-nav-tabs .note-nav-item {
  margin-bottom: -1px
}

.note-modal .note-nav-tabs .note-nav-link {
  border: 1px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem
}

.note-modal .note-nav-tabs .note-nav-link:focus,
.note-modal .note-nav-tabs .note-nav-link:hover {
  border-color: #e9ecef #e9ecef #ddd
}

.note-modal .note-nav-tabs .note-nav-link.disabled {
  color: #868e96;
  background-color: transparent;
  border-color: transparent
}

.note-modal .note-nav-tabs .note-nav-item.show .note-nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #ddd #ddd #fff
}

.note-modal .note-tab-content {
  margin: 15px auto
}

.note-modal .note-tab-content>.note-tab-pane,
.note-modal .note-tab-content>.note-tab-pane:target~.note-tab-pane:last-child {
  display: none
}

.note-modal .note-tab-content>.note-tab-pane:target,
.note-modal .note-tab-content>:last-child {
  display: block
}

.note-form-group {
  padding-bottom: 20px
}

.note-form-group:last-child {
  padding-bottom: 0
}

.note-form-label {
  display: block;
  width: 100%;
  font-size: 16px;
  color: #42515f;
  margin-bottom: 10px;
  font-weight: 700
}

.note-input {
  width: 100%;
  display: block;
  border: 1px solid #ededef;
  background: #fff;
  outline: 0;
  padding: 6px 4px;
  font-size: 14px;
  -ms-box-sizing: border-box;
  box-sizing: border-box
}

.note-input::-webkit-input-placeholder {
  color: #eee
}

.note-input:-moz-placeholder,
.note-input::-moz-placeholder {
  color: #eee
}

.note-input:-ms-input-placeholder {
  color: #eee
}

.note-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-size: 13px;
  transition: opacity .15s;
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0)
}

.note-tooltip.in {
  -webkit-opacity: .9;
  -khtml-opacity: .9;
  -moz-opacity: .9;
  opacity: .9;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=90);
  filter: alpha(opacity=90)
}

.note-tooltip.top {
  margin-top: -3px;
  padding: 5px 0
}

.note-tooltip.right {
  margin-left: 3px;
  padding: 0 5px
}

.note-tooltip.bottom {
  margin-top: 3px;
  padding: 5px 0
}

.note-tooltip.left {
  margin-left: -3px;
  padding: 0 5px
}

.note-tooltip.bottom .note-tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000
}

.note-tooltip.top .note-tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000
}

.note-tooltip.right .note-tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000
}

.note-tooltip.left .note-tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000
}

.note-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid
}

.note-tooltip-content {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000
}

.note-popover {
  position: absolute;
  z-index: 1060;
  display: block;
  font-size: 13px;
  display: none;
  background: #fff;
  border: 1px solid #ccc
}

.note-popover.in {
  display: block
}

.note-popover.top {
  margin-top: -10px;
  padding: 5px 0
}

.note-popover.right {
  margin-left: 10px;
  padding: 0 5px
}

.note-popover.bottom {
  margin-top: 10px;
  padding: 5px 0
}

.note-popover.left {
  margin-left: -10px;
  padding: 0 5px
}

.note-popover.bottom .note-popover-arrow {
  top: -11px;
  left: 20px;
  margin-left: -10px;
  border-top-width: 0;
  border-bottom-color: #999;
  border-bottom-color: rgba(0, 0, 0, .25)
}

.note-popover.bottom .note-popover-arrow:after {
  top: 1px;
  margin-left: -10px;
  content: " ";
  border-top-width: 0;
  border-bottom-color: #fff
}

.note-popover.top .note-popover-arrow {
  bottom: -11px;
  left: 20px;
  margin-left: -10px;
  border-bottom-width: 0;
  border-top-color: #999;
  border-top-color: rgba(0, 0, 0, .25)
}

.note-popover.top .note-popover-arrow:after {
  bottom: 1px;
  margin-left: -10px;
  content: " ";
  border-bottom-width: 0;
  border-top-color: #fff
}

.note-popover.right .note-popover-arrow {
  top: 50%;
  left: -11px;
  margin-top: -10px;
  border-left-width: 0;
  border-right-color: #999;
  border-right-color: rgba(0, 0, 0, .25)
}

.note-popover.right .note-popover-arrow:after {
  left: 1px;
  margin-top: -10px;
  content: " ";
  border-left-width: 0;
  border-right-color: #fff
}

.note-popover.left .note-popover-arrow {
  top: 50%;
  right: -11px;
  margin-top: -10px;
  border-right-width: 0;
  border-left-color: #999;
  border-left-color: rgba(0, 0, 0, .25)
}

.note-popover.left .note-popover-arrow:after {
  right: 1px;
  margin-top: -10px;
  content: " ";
  border-right-width: 0;
  border-left-color: #fff
}

.note-popover-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border: 11px solid transparent
}

.note-popover-arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  content: " ";
  border: 10px solid transparent
}

.note-popover-content {
  padding: 3px 8px;
  color: #000;
  text-align: center;
  background-color: #fff;
  min-width: 100px;
  min-height: 30px
}

@-moz-document url-prefix() {
  .note-modal .note-image-input {
    height: auto
  }
}

.help-list-item label {
  margin-bottom: 5px;
  display: inline-block
}