
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#Login,
div[id^="Signup"],
div[id^="Find"] {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - #{$header-height}  - 140px);
    background-color: rgba(240, 240, 240, 0.2);

    .box {
        margin: auto;
        border-radius: 8px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
        background-color: #fff;

        .title {
            margin-bottom: 7px;
            font-size: 30px;
            font-family: NanumSquareOTF_acB;
            line-height: 30px;
            color: var(--color-Scorpion);
        }

        .desc {
            margin-bottom: 51px;
            font-size: 14px;
            font-family: NanumSquareOTF_acB;
            color: var(--color-Silver-Chalice);
            white-space: pre;
        }

        .wrap_input {
            .input_box {
                position: relative;
                display: flex;
                flex-direction: column-reverse;

                label {
                    margin-bottom: 4px;
                    display: block;
                    font-size: 12px;
                    color: var(--color-Scorpion);
                }

                input,
                .input {
                    width: 100%;
                    height: 34px;
                    font-size: 14px;
                    line-height: 34px;
                    border: none;
                    border-bottom: 1px solid var(--color-Alto);

                    &:focus {
                        outline: none;
                        border-bottom: 1px solid var(--login-focus-color);

                        & ~ label {
                            color: var(--login-focus-color);
                        }
                    }

                    &.error {
                        border-bottom: 1px solid var(--login-error-color);

                        & ~ label {
                            color: var(--login-error-color);
                        }
                    }
                }
            }

            .input_box+.input_box {
                margin-top: 22px;
            }
        }
    }

    .btn {
        position: absolute;
        right: 0;
        bottom: 8px;
        width: 69px;
        height: 32px;
        font-size: 12px;
        font-family: NanumSquareOTF_acB;
        color: #ffffff;
        border-radius: 4px;
        background-color: var(--login-focus-color);
    }

    .btn_next {
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 114px;
        height: 43px;
        color: #ffffff;
        border-radius: 4px;
        background-color: var(--login-focus-color);
    }

    .notify {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 800px;
        height: 70px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
        background-color: rgba(216, 216, 216, 0.3);

        p {
            margin-right: 16px;
            font-size: 14px;
            font-family: NanumSquareOTF_acB;
            color: var(--color-Scorpion);
        }

        a {
            font-size: 14px;
            font-family: NanumSquareOTF_acB;
            color: var(--color-Cornflower-Blue);
            text-decoration: underline;
        }
    }
}