
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#UserInfoEdit {
  padding: 20px;

  .head {
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    align-content: center;

    button {
      width: 127px;
      height: 43px;
      font-size: 14px;
      font-family: NanumSquareOTF_acB;
      color: #ffffff;
      border-radius: 4px;
    }

    .btn_edit {
      background-color: var(--color-Cornflower-Blue);
    }

    .btn_save {
      background-color: var(--color-Niagara);
    }
  }

  .col {

    .row {
      display: flex;
      flex-direction: column-reverse;
      width: 328px;
    }

    h2,
    label {
      margin-bottom: 8px;
      display: block;
      font-size: 12px;
      color: var(--color-Scorpion);
    }

    p {
      padding: 7px 0;
      height: 32px;
      font-size: 14px;
      border-bottom: 1px solid var(--color-Alto);

      &.not_edit {
        color: var(--color-Silver-Chalice);
      }
    }
  }

  .col+.col {
    margin-top: 20px;
  }

  .col2 {
    display: flex;
    align-content: center;
    column-gap: 20px;
  }

  .inner {
    position: relative;

    input {
      padding-right: 90px;
    }

    .btn_search_address {
      position: absolute;
      right: 0;
      bottom: 4px;
      width: 80px;
      height: 32px;
      font-size: 12px;
      font-family: NanumSquareOTF_acB;
      color: #ffffff;
      border-radius: 4px;
      background-color: #4593f5;
    }
  }

  input {
    padding: 7px 0;
    width: 100%;
    font-size: 14px;
    border: none;
    border-bottom: 1px solid var(--color-Alto);
    outline: none;

    &:focus {
      outline: none;
      border-bottom: 1px solid var(--login-focus-color);

      & + label {
        color: var(--login-focus-color);
      }
    }

    &.error {
      border-bottom: 1px solid var(--login-error-color);

      & ~ label {
        color: var(--login-error-color);
      }
    }
  }
}