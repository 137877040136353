
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#ArticleList {

  .item {
    padding: 15px 10px;
    display: flex;
    align-content: center;
    column-gap: 15px;
    height: 150px;
    border-bottom: 1px solid #f0f0f0;

    @include mobile {
      padding: 10px 0;
      min-height: 90px;
      height: auto;
    }


    img {
      min-width: 160px;
      width: 160px;
      height: 120px;
      object-fit: contain;
      border: 1px solid #f0f0f0;

      @include mobile {
        min-width: 98px;
        width: 98px;
        height: 70px;
      }
    }

    &_info {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      &_title {
        margin-bottom: 5px;
        font-size: 16px;
        font-family: NanumSquareOTF_acEB;
        line-height: 24px;
        @include text-ellipsis(1);

        @include mobile {
          line-height: 18px;
          @include text-ellipsis(2);
        }

        &:hover {
          text-decoration: underline;
        }
      }

      &_desc {
        font-size: 14px;
        line-height: 18px;
        @include text-ellipsis(3);

        @include mobile {
          display: none;
        }
      }

      &_upload_info {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        column-gap: 12px;

        @include mobile {
          justify-content: flex-start;

          .section_name {
            display: none;
          }
        }

        p {
          position: relative;
          display: flex;
          align-items: center;
          column-gap: 4px;
          font-size: 12px;
          font-family: NanumSquareOTF_acB;
          line-height: 24px;
          color: var(--color-Dusty-Gray);
        }

        p + p {
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: -6px;
            transform: translateY(-50%);
            width: 1px;
            height: 14px;
            border-right: 1px solid var(--color-Alto);
          }
        }
      }
    }
  }
}