
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#Login {

    .box {
        padding: 30px;
        width: 388px;

        .option {
            margin: 15px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 24px;

            .btn_save_id {
                label {
                    margin-left: 5px;
                    font-size: 14px;
                    line-height: 18px;
                }
            }

            .links {
                display: flex;
                align-items: center;
                column-gap: 23px;

                a {
                    font-size: 12px;
                    color: var(--color-Scorpion);
                }
            }
        }

        .btn_next {
            margin: 0 0 0 auto;
        }
    }
}