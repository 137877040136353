
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#Home {
    margin: auto;
    padding-top: 20px;
    width: 1280px;

    .wrap {
        padding: 0 20px;
        display: flex;
        align-content: center;

        @include mobile {
            padding: 0;

            .article_area {
                width: 100% !important;
            }
        }

        .area_head {
            margin-bottom: 5px;
            display: flex;
            justify-content: space-between;
            align-content: center;

            .title {
                font-size: 16px;
                font-family: NanumSquareOTF_acEB;
                line-height: 28px;
            }
        }
    }

    .wrap_article_side {
        column-gap: 15px;

        .article_area {
            width: 850px;
        }
    }

    .wrap_article {
        margin: 15px 0;
        column-gap: 40px;

        @include mobile {
            flex-direction: column;
            row-gap: 15px;
        }

        .article_area {
            width: 581px;
            min-height: 178px;
        }
    }

    .slide_area {
        margin: 15px 0;
        padding: 8px 0 27px 40px;
        height: 260px;
        background-color: rgba(240, 240, 240, 0.2);

        @include mobile {
            padding: 0;
            height: auto;
            background-color: transparent;
        }

        .slide_head {
            margin-bottom: 7px;
            padding-right: 40px;
            display: flex;
            justify-content: space-between;
            align-content: center;

            @include mobile {
                margin-bottom: 15px;
                padding-right: 0;
            }

            .title {
                font-size: 16px;
                font-family: NanumSquareOTF_acEB;
                line-height: 28px;
            }

            .set_area {
                display: flex;
                align-content: center;
                column-gap: 6px;
            }
        }

        .slider {
            max-height: 190px;
            overflow: hidden;

            a {
                outline: none;
            }
            img {
                padding: 0 5px;
                width: 250px;
                height: 190px;
                object-fit: contain;

                @include mobile {
                    width: 130px;
                    height: 100px;
                }
            }
        }
    }

    .has_side {
        margin-bottom: 10px;
        column-gap: 15px;

        .article_area {
            width: 850px;
        }
    }

    .btn_more {
        display: block;
        width: 91px;
        height: 28px;
        background: url("../../../assets/image/common/btn-more.svg") no-repeat;
    }

    @include mobile {
        width: 90vw;
    }
}