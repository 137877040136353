
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#ReporterArticleSaveList {
  padding: 20px;
  min-width: 970px;

  table {
    width: 100%;

    thead {
      background-color: var(--table-head-background-color);

      th {
        font-family: NanumSquareOTF_acB;
      }
    }

    th,
    td {
      height: 32px;
      font-size: 12px;
      text-align: center;
    }

    td {
      border-bottom: 1px solid var(--color-Alto);
    }

    th:nth-of-type(3),
    td:nth-of-type(3) {
      padding-left: 10px;
      text-align: left;
    }
  }

  .btn_write {
    width: 116px;
    height: 24px;
    background: url("../../../assets/image/common/btn-detai-write.svg") no-repeat;
  }

  .btn_del {
    width: 50px;
    height: 24px;
    font-size: 12px;
    font-family: NanumSquareOTF_acB;
    color: #ffffff;
    text-align: center;
    border-radius: 9px;
    background-color: var(--color-Alizarin-Crimson);
  }
}