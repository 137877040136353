
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#Header {
  margin: auto;
  position: relative;
  top: 0;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1280px;
  height: 100px;
  background-color: #ffffff;
  z-index: 2;

  .logo {
    font-family: NanumSquareOTF_acEB;
    object-fit: contain;
  }

  .btn_list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;

    @include mobile {
      display: none;
    }

    li {
      p,
      a {
        font-size: 12px;
        font-family: NanumSquareOTF_acB;
        letter-spacing: 0;
        color: var(--color-Dusty-Gray);
      }
    }
  }
}