
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#Pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-content: center;

  .btn_first,
  .btn_next {
    width: 55px;
    border-radius: 4px;
  }

  .btn_first {
    margin-right: 5px;
    background: var(--color-Alabaster) url("../../../assets/image/common/ic-double-arrow-left.svg") no-repeat center
  }

  .btn_prev {
    border-right: transparent;
    background: var(--color-Alabaster) url("../../../assets/image/common/ic-arrow-left.svg") no-repeat center
  }

  .btn_last {
    background: var(--color-Alabaster) url("../../../assets/image/common/ic-arrow-right.svg") no-repeat center
  }

  .btn_next {
    margin-left: 5px;
    background: var(--color-Alabaster) url("../../../assets/image/common/ic-double-arrow-right.svg") no-repeat center
  }

  button {
    display: block;
    width: 36px;
    height: 36px;
    border: 1px solid var(--color-Alto);
    background-color: var(--color-Alabaster);
  }

  .number {
    font-size: 14px;
    line-height: 36px;
    text-align: center;
    border-right: transparent;

    &.active {
      color: #ffffff;
      border-color: transparent;
      background-color: var(--color-Cornflower-Blue);
      pointer-events: none;
    }
  }

  .not_click {
    pointer-events: none;
  }
}