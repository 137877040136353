
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#SideArticleListCategory {
  padding: 17px 0;
  width: 375px;
  border: 1px solid var(--color-Alto);

  @include mobile {
    display: none;
  }

  .side_head {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;

    button {
      position: relative;
      width: 33.3%;
      font-size: 16px;
      font-family: NanumSquareOTF_acEB;
      line-height: 28px;
      background-color: #ffffff;
      word-break: keep-all;

      &.active {
        color: var(--color-Cornflower-Blue);
      }

      &:nth-of-type(n):after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        width: 1px;
        height: 50%;
        transform: translateY(-50%);
        background-color: var(--color-Alto);
      }
    }
  }

  .news {
    padding: 0 12.5px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
    width: 375px;

    .item {
      width: 110px;
      height: 175px;
      border: 1px solid #f0f0f0;

      &:hover {
        p {
          text-decoration: underline;
        }
      }

      img {
        min-width: 110px;
        width: 110px;
        min-height: 120px;
        height: 120px;
        object-fit: contain;
      }

      p {
        height: 55px;
        font-size: 14px;
        font-family: NanumSquareOTF_acEB;
        line-height: 18px;
        @include text-ellipsis(3);
      }
    }
  }

  .btn_more {
    margin: 20px 10px 0 auto;
  }
}