
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#ArticleReporter {
  padding: 30px 0;
  display: flex;
  justify-content: center;
  column-gap: 15px;

  @include mobile {
    padding: 0 0 20px 0;
  }

  .content {
    width: 845px;

    .reporter_info {
      margin: 10px 0 35px 0;
      padding: 25px 15px;
      display: flex;
      align-content: center;
      column-gap: 11px;
      border-top: 1px solid var(--color-Gallery);
      border-bottom: 1px solid var(--color-Gallery);

      @include mobile {
        margin: 0 0 20px 0;
        padding: 10px 15px;
      }

      .profile_image {
        object-fit: contain;
      }

      .inner {
        h2 {
          margin-bottom: 10px;
          font-size: 30px;
          font-family: NanumSquareOTF_acEB;

          @include mobile {
            font-size: 20px;
          }
        }

        p {
          font-size: 14px;
          font-family: NanumSquareOTF_acB;
          color: var(--color-Scorpion);
        }
      }
    }

    #ArticleListAndOneImage {
      @include mobile {
        margin-bottom: 30px;
        padding: 0 var(--left-right-padding);
      }

      .left_content {
        .title {
          font-size: 20px;
          color: var(--default-text-color);
        }
      }
    }

    .reporter_recent {
      padding: 0 var(--left-right-padding);
      h2 {
        font-size: 20px;
        font-family: NanumSquareOTF_acEB;

        @include mobile {
          margin-bottom: 5px;
        }
      }
    }
  }
}