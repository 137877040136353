
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#Section {
  margin: auto;
  padding: 25px 0 50px 0;
  display: flex;
  align-content: center;
  column-gap: 15px;
  max-width: 1280px;

  @include mobile {
    padding: 0 0 25px 0;
    min-height: calc(100vh - 234px);
  }

  .main_content {
    width: 100%;
    max-width: 890px;

    .tab {
      padding: 5px 0;
      display: flex;
      column-gap: 12px;
      width: 100%;
      min-height: 37px;
      overflow-y: scroll;
      border-bottom: 1px solid #f0f0f0;
    }

    #ArticleList {
      padding-top: 8px;
    }
  }

  .MuiTabs-root {
    border-bottom: 1px solid var(--color-Alto);
    overflow-y: scroll;
  }

  .MuiButtonBase-root {
    min-width: 135px;
    font-size: 14px;
    overflow-y: scroll;
    word-break: keep-all;
  }

  .MuiTabs-scroller {
    overflow-y: scroll;
  }

  .MuiTabs-indicator {
    display: none;
  }

  .Mui-selected {
    color: var(--color-Cornflower-Blue) !important;
  }

  #ArticleList {

    @include mobile {
      margin: 0 var(--left-right-padding) 25px var(--left-right-padding);
    }
  }
}