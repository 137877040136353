
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#AdminTaskRequestList {
  padding: 20px;

  table {

    th:nth-of-type(2),
    td:nth-of-type(2) {
      padding-left: 10px;
      text-align: left;
    }

    td {
      cursor: pointer;
    }
  }
}