
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#ArticleContainer {
  display: flex;
  align-content: center;

  .title {
    margin-bottom: 20px;
    font-size: 20px;
    font-family: NanumSquareOTF_acEB;
    line-height: 24px;
  }
}