
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#SectionSide {
  position: sticky;
  top: 90px;
  max-height: 777px;

  @include mobile {
    display: none;
  }

  #ArticleListText {
    margin-bottom: 35px;
  }
}