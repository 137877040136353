
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#SignupInput {

    .has_button {
        padding-right: 80px;
    }

    .box {
        padding: 38px 30px 26px 30px;
        width: 388px;
    }

    .btn_check {
        width: 69px;

        &.use {
            opacity: 0.3;
            pointer-events: none;
        }
    }

    .btn_search {
        width: 95px;
    }

    .btn_next {
        margin-top: 22px;
    }
}