
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#AdminArticleMange {
    margin-top: 20px;
    padding: 20px;

    table {
        th:nth-of-type(2),
        td:nth-of-type(2) {
            padding-left: 10px;
            text-align: left;
        }

        .ellipsis {
            @include text-ellipsis(1);
        }
    }

    .btns {
        display: flex;
        justify-content: center;
        align-content: center;
        column-gap: 5px;

        button[class^="btn_"] {
            width: 47px;
            height: 24px;
            font-size: 12px;
            font-family: NanumSquareOTF_acB;
            color: #ffffff;
            text-align: center;
            border-radius: 9px;
        }

    }

    .btn_info {
        padding-left: 10px;
        color: #959595 !important;
        text-align: left !important;
        background: url("../../../assets/image/common/ic-arrow-right-more.svg") no-repeat 85% 6px;
        background-color: var(--color-Link-Water);
    }

    .btn_accept {
        background-color: var(--color-Niagara);
    }

    .btn_del {
        background-color: var(--color-Alizarin-Crimson);
    }
}