
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#AdminSectionManage {
  padding: 20px;

  table {
    border-spacing: 0;

    tbody {
      tr {
        background-color: #ffffff !important;
      }
    }

    th,
    td {
      height: 40px;
    }

    .order {
      width: 10%;
      max-width: 10%;
    }

    .section_type {
      width: 20%;
      max-width: 20%;
    }

    .section_name {
      width: 50%;
      max-width: 50%;
      text-align: left;
    }

    .manage {
      width: 20%;
      max-width: 20%;
    }
  }

  .btns {
    display: flex;
    justify-content: center;
    align-content: center;
    column-gap: 5px;
  }

  .btn_move,
  .btn_edit,
  .btn_delete {
    width: 50px;
    height: 24px;
    font-size: 12px;
    font-family: NanumSquareOTF_acB;
    border-radius: 9px;
  }

  .btn_move {
    color: var(--color-Dusty-Gray);
    background-color: #eff3fb;
  }

  .btn_edit {
    color: #ffffff;
    background-color: var(--color-Seagull);
  }

  .btn_delete {
    color: #ffffff;
    background-color: var(--color-Alizarin-Crimson);
  }

  .btn_add {
    display: flex;
    justify-content: center;
    align-content: center;
    column-gap: 8px;
    margin-left: auto;
    margin-bottom: 15px;
    width: 142px !important;
    height: 42px !important;
    background-color: #4593f5;

    i {
      height: 42px;
    }

    span {
      line-height: 42px;
    }
  }

  .btn_save {
    margin: 30px auto 0 auto;
    display: block;
    width: 109px;
    height: 42px;
    background-color: var(--color-Niagara);
  }

  .btn_add,
  .btn_save {
    font-size: 14px !important;
    font-family: NanumSquareOTF_acEB;
    line-height: 42px !important;
    color: #ffffff;
    border-radius: 4px !important;
  }
}