
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#AdminTaskRequestAdd {
  padding: 20px;

  .content {
    padding-top: 30px;

    .col {
      margin-bottom: 15px;
      .row {
        display: flex;
        justify-content: space-between;
        align-content: center;

        .row_title {
          width: 75px;
          font-size: 14px;
          font-family: NanumSquareOTF_acEB;
          line-height: 42px;
        }

        input {
          padding: 0 14px;
          width: 895px;
          height: 42px;
          font-size: 14px;
          border-radius: 4px;
          border: 1px solid var(--color-Alto);
        }
      }
    }
  }

  .btn_add {
    margin: 40px auto;
    display: block;
    width: 105px;
    height: 42px;
    font-size: 14px;
    font-family: NanumSquareOTF_acB;
    color: #ffffff;
    border-radius: 4px;
    background-color: #05ac9e;
  }

  .toastui-editor-defaultUI {
    width: 100%;
  }

  .toastui-editor-tooltip {
    .text {
      color: #ffffff !important;
    }
  }
}