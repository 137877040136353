
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#ArticleListImageAndText {
  padding: 15px 10px;
  width: 375px;
  border: 1px solid var(--color-Alto);

  @include mobile {
    display: none;
  }

  .side_title {
    margin-bottom: 17px;
    font-size: 20px;
    font-family: NanumSquareOTF_acEB;
  }

  .list {
    li {
      margin: 5px;

      a {
        display: flex;
        align-items: center;
        column-gap: 5px;
        height: 76px;
        border-bottom: 1px solid #f0f0f0;

        &:hover {
          text-decoration: underline;
        }

        .rank {
          width: 24px;
          line-height: 24px;
          font-size: 18px;
          font-family: NanumSquareOTF_acEB;
          color: var(--color-Cornflower-Blue);
          text-align: center;
        }

        .title {
          width: 210px;
          font-family: NanumSquareOTF_acEB;
          font-size: 14px;
          @include text-ellipsis(2);
        }

        img {
          width: 100px;
          height: 65px;
          object-fit: contain;
        }
      }
    }
  }
}