
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#ArticleListAndOneImage {
  margin-bottom: 45px;
  display: flex;
  align-items: center;
  column-gap: 10px;

  @include mobile {
    margin-bottom: 20px;
  }

  .left_content {
    width: 100%;
    .title {
      margin-bottom: 7px;
      font-size: 16px;
      font-family: NanumSquareOTF_acEB;
      line-height: 28px;
      color: var(--color-Cornflower-Blue);
    }
    .list {
      li {
        a {
          position: relative;
          display: flex;
          align-items: center;
          height: 35px;
          border-bottom: 1px solid #f0f0f0;

          &:before {
            content: "";
            position: relative;
            left: 0;
            margin-right: 9px;
            min-width: 6px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: var(--color-Cornflower-Blue);
          }

          p {
            font-size: 14px;
            font-family: NanumSquareOTF_acEB;
            line-height: 18px;
            @include text-ellipsis(1);
          }
        }
      }

      li + li {
        margin-top: 2px;
      }
    }
  }

  .right_content {
    position: relative;

    @include mobile {
      display: none;
    }

    img {
      width: 250px;
      height: 219px;
      background-color: rgba(0, 0, 0, 0.8);
    }
    .title {
      position: absolute;
      left: 10px;
      bottom: 12px;
      width: 230px;
      font-size: 14px;
      font-family: NanumSquareOTF_acEB;
      line-height: 18px;
      color: #ffffff;
    }
  }
}