
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
footer {
  padding: 31px 0 12px 0;
  background-color: #ffffff;
  border-top: 1px solid var(--color-Alto);

  .footer_area {
    margin: auto;
    max-width: 1280px;

    @include mobile {
      padding: 0 15px;
    }
  }

  .info_area {
    display: flex;
    align-content: center;
    height: 60px;

    .logo {
      margin-right: 53px;
      font-size: 22px;
      font-family: NanumSquareOTF_acB;
      object-fit: contain;

      @include mobile {
        display: none;
      }
    }

    .info {
      width: 715px;
      font-size: 12px;
      line-height: 20px;
      color: var(--color-Dusty-Gray);
    }
  }

  .desc_area {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-content: center;

    @include mobile {
      display: block;
    }

    .link {
      @include mobile {
        margin-bottom: 20px;
      }
      a {
        position: relative;
      }

      a+a {
        margin-left: 30px;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: -15px;
          width: 1px;
          height: 12px;
          background-color: var(--color-Dusty-Gray);
        }
      }
    }

    p,
    a {
      font-size: 12px;
      line-height: 20px;
      color: var(--color-Dusty-Gray);
    }
  }
}