
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#ArticlePopup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    max-height: 80vh;
    height: auto !important;
    overflow-y: scroll;
    border: 1px solid var(--color-Alto);
    background-color: #ffffff;
    z-index: 2;

    .popup_head {
        margin: 0 12px 0 20px;
        display: flex;
        justify-content: space-between;
        align-content: center;
        height: 55px;
        border-bottom: 1px solid var(--color-Alto);

        h2 {
            font-size: 20px;
            font-family: NanumSquareOTF_acEB;
            line-height: 55px;
        }
    }

    .title {
        display: none;
    }
}