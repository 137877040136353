
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#EditorLibrary.library {
  padding: 20px;
  min-width: 340px;
  width: 25vw;
  height: 100%;

  .title {
    margin-bottom: 30px;
    font-size: 20px;
    font-family: NanumSquareOTF_acEB;
    line-height: 24px;
  }

  .head {
    display: flex;
    justify-content: space-between;
    align-content: center;

    .inner {
      p {
        font-size: 12px;
        font-family: NanumSquareOTF_acB;
        color: var(--color-Dusty-Gray);
      }
    }

    .btn_add {
      margin: auto 0;
      display: block;
      width: 56px;
      height: 32px;
      font-size: 12px;
      font-family: NanumSquareOTF_acB;
      color: #ffffff;
      border-radius: 4px;
      background-color: var(--color-Cornflower-Blue);
    }
  }

  .lib_image_title,
  .lib_file_title {
    font-size: 14px;
    font-family: NanumSquareOTF_acEB;
    line-height: 32px;
  }

  .lib_image_area {

    .lib_image_list {
      margin-top: 15px;
      display: flex;
      align-content: center;
      flex-wrap: wrap;
      column-gap: 10px;
      row-gap: 10px;

      .lib_image_item {
        position: relative;

        img {
          width: 110px;
          height: 110px;
          object-fit: contain;
          border: 1px solid var(--color-Alto);
        }

        .thumbnail {
          padding: 5px;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.8);

          p {
            font-size: 14px;
            font-family: NanumSquareOTF_acEB;
            line-height: 20px;
            color: #ffffff;
          }
        }
      }
    }
  }

  .lib_file_area {
    margin-top: 50px;

    .lib_file_list {
      margin-top: 8px;
    }

    .lib_file_item {
      padding: 5px 0;
      display: flex;
      justify-content: space-between;
      min-height: 65px;
      border-bottom: 1px solid var(--color-Alto);

      .info {
        width: 90%;

        .filename {
          font-size: 14px;
          line-height: 28px;
          @include text-ellipsis(1);
        }

        .size {
          font-size: 12px;
          font-family: NanumSquareOTF_acB;
          line-height: 24px;
          color: var(--color-Dusty-Gray);
        }
      }
    }
  }
}