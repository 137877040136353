
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#AnnouncementBoard {
  padding-top: 17px;
  width: 850px;

  .board_head {
    display: flex;
    justify-content: space-between;
    align-content: center;

    .sort_area {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      button {
        padding: 0 20px;
        position: relative;
        max-width: 145px;
        height: 24px;
        font-size: 16px;
        font-family: NanumSquareOTF_acEB;
        background-color: #ffffff;

        &.active {
          color: var(--color-Cornflower-Blue);
        }

        &:first-child:before {
          content: "";
          position: absolute;
          top: 5px;
          left: 0;
          width: 1px;
          height: 14px;
          background-color: var(--color-Alto);
        }

        &:after {
          content: "";
          position: absolute;
          top: 5px;
          right: 0;
          width: 1px;
          height: 14px;
          background-color: var(--color-Alto);
        }
      }
    }
  }

  .btn_more {
    display: block;
    min-width: 91px;
    height: 28px;
    background: url("../../../assets/image/common/btn-more.svg") no-repeat;
  }
}