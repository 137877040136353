
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#JoinMediaList {
    padding: 20px;

    .media_list {
        margin: 60px 0 0 0;
        display: flex;
        align-items: center;

        flex-wrap: wrap;
        column-gap: 25px;
        row-gap: 25px;

        .media_item {
            width: 220px;
            height: 240px;
            background-color: #fff;
            cursor: pointer;

            img {
                width: 220px;
                height: 175px;
                object-fit: contain;
            }
            p {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 65px;
                font-size: 16px;
                font-family: NanumSquareOTF_acEB;
                border-radius: 0 0 5px 5px;
                border: solid 1px var(--color-Gallery);
                background-color: #fff;
            }
        }
    }
}