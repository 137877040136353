
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#JoinMediaSignIn {
  padding: 20px;

  .search_area {
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 5px;

    input {
      padding: 0 10px;
      width: 300px;
      height: 32px;
      font-size: 14px;
      font-family: NanumSquareOTF_acEB;
      border: 1px solid var(--color-Alto);
      border-radius: 4px;
    }
  }

  table {

    img {
      width: 100px;
      height: 80px;
      object-fit: contain;
    }

    h2 {
      font-size: 14px;
      font-family: NanumSquareOTF_acEB;
    }

    button {
      width: 99px;
      height: 24px;
      color: #ffffff;
      border-radius: 9px;
      background-color: var(--color-Niagara);
    }

    th:nth-child(2),
    td:nth-child(2) {
      padding-left: 15px;
      text-align: left;
    }
  }
}