
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#AdminTaskRequestDetail {
  padding: 30px;

  .board {
    .title {
      padding: 15px 0;
      font-size: 16px;
      font-family: NanumSquareOTF_acB;
      border-bottom: 1px solid var(--color-Gallery);
    }

    .date {
      margin: 10px 0;
      display: flex;
      justify-content: end;
      column-gap: 4px;

      p {
        font-size: 12px;
        font-family: NanumSquareOTF_acB;
        color: var(--color-Dusty-Gray);
      }
    }

    .content {
      min-height: 50px;
    }
  }

  .comment_area {
    padding: 15px 0;
    border-top: 1px solid var(--color-Gallery);
    border-bottom: 1px solid var(--color-Gallery);

    .inner {
      margin-bottom: 10px;
      display: flex;
      align-content: center;
      column-gap: 8px;

      img {
        width: 32px;
        height: 32px;
      }

      .state {
        font-size: 16px;
        font-family: NanumSquareOTF_acEB;
        line-height: 32px;
        color: var(--color-Cornflower-Blue);
      }
    }
  }

  .btn_list {
    margin: 20px auto 0 auto;
    display: flex;
    justify-content: center;
    align-content: center;
    column-gap: 8px;
    width: 161px;
    height: 42px;
    border: 1px solid var(--color-Alto);
    border-radius: 4px;
    background-color: #ffffff;

    i {
      height: 42px;
    }

    span {
      font-size: 14px;
      font-family: NanumSquareOTF_acEB;
      line-height: 42px;
    }
  }
}