
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#ArticleDetail {
  margin: auto;
  max-width: 1280px;

  .header {
    padding-top: 15px;
    max-width: 1215px;

    @include mobile {
      padding-top: var(--left-right-padding);
    }

    .header_post {
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #f0f0f0;

      &_title {
        padding-bottom: 2px;
        width: 950px;
        font-size: 30px;
        font-family: NanumSquareOTF_acEB;
        line-height: 40px;

        @include mobile {
          padding: 0 var(--left-right-padding) 0 var(--left-right-padding);
        }
      }

      .links {
        padding-top: 5px;
        display: flex;
        column-gap: 15px;
      }

      &_info {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 14px;
        height: 24px;

        @include mobile {
          padding: 0 var(--left-right-padding);
        }

        p {
          position: relative;
          display: flex;
          align-items: center;

          span {
            font-size: 12px;
            color: var(--color-Dusty-Gray);
          }
        }

        p+p {
          &:before {
            content: "";
            position: absolute;
            left: -5px;
            width: 1px;
            height: 14px;
            background-color: var(--color-Alto);
          }
        }
      }
    }
  }

  .wrap_section {
    padding: 35px 0;
    display: flex;
    column-gap: 45px;

    @include mobile {
      padding: 20px var(--left-right-padding);
    }

    .main_section {
      width: 800px;

      @include mobile {
        width: 100vw;
      }

      &_report {
        margin: 30px 0;
        border-top: 1px solid #f0f0f0;
        border-bottom: 1px solid #f0f0f0;

        &_wrap {
          padding: 20px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;

          @include mobile {
            padding: 10px 0;
            display: block;
          }
        }

        &_info {
          display: flex;
          align-items: center;
          column-gap: 11px;

          img {
            width: 46px;
            height: 46px;
            background-size: contain;

            @include mobile {
              width: 32px;
              height: 32px;
            }
          }

          &_media {
            font-size: 14px;
            font-family: NanumSquareOTF_acEB;
            line-height: 18px;
          }

          &_email {
            position: relative;
            font-size: 12px;
            font-family: NanumSquareOTF_acB;
            color: var(--color-Dusty-Gray);

            &:before {
              content: "";
              position: absolute;
              left: -5px;
              width: 1px;
              height: 14px;
              background-color: var(--color-Alto);
            }
          }
        }

        &_copyright {
          font-size: 12px;
          font-family: NanumSquareOTF_acB;
          line-height: 24px;
          color: var(--color-Dusty-Gray);
          text-align: right;
        }

        .btn_another_article {
          display: block;
          width: 108px;
          line-height: 24px;
          font-size: 12px;
          font-family: NanumSquareOTF_acB;
          color: var(--color-Dusty-Gray);
          text-align: center;
          border-radius: 9px;
          background-color: var(--color-Link-Water);

          @include mobile {
            margin-left: auto;
          }
        }
      }

      &_file_list {
        .file_item:first-child {
          border-top: 1px solid #f0f0f0;
        }

        .file_item {
          padding: 5px 0;
          border-bottom: 1px solid #f0f0f0;

          button {
            display: flex;
            align-items: center;
            column-gap: 10px;
            width: 100%;
            background-color: transparent;

            i {
              width: 30px;
              height: 30px;
              border-radius: 4px;
              background-color: var(--color-Alto);
            }

            .file_name {
              width: 680px;
              font-size: 14px;
              font-family: NanumSquareOTF_acEB;
              text-align: left;
            }

            .file_size {
              width: 76px;
              font-size: 12px;
              font-family: NanumSquareOTF_acB;
              color: var(--color-Dusty-Gray);
              text-align: right;
            }
          }
        }
      }
    }
  }
}