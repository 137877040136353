
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#Menubar {
  position: relative;
  background-color: #fff;
  z-index: 1;

  .tool {
    display: flex;
    justify-content: center;
    align-content: center;
    column-gap: 34px;
  }

  .MuiTabs-root {
    border-bottom: 1px solid var(--color-Alto);
  }

  .MuiButtonBase-root {
    min-width: 200px;
    width: 200px;
    font-size: 16px;

    @include mobile {
      min-width: 90px;
      width: auto;
      height: 38px;
      font-size: 14px;
    }

    &:hover {
      color: var(--color-Cornflower-Blue) !important;
    }
  }

  .MuiTabs-flexContainer {
    justify-content: center;

    @include mobile {
      justify-content: flex-start;
    }
  }

  @include mobile {
    margin: 0;
    width: 100vw;
  }
}