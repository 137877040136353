
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#ArticleListText {
  padding: 15px 10px;
  width: 375px;
  border: 1px solid var(--color-Alto);

  .side_title {
    margin-bottom: 17px;
    font-size: 20px;
    font-family: NanumSquareOTF_acEB;
  }

  .list {
    li {
      a {
        position: relative;
        padding-left: 25px;
        display: flex;
        align-items: center;
        height: 35px;

        &:before {
          content: "";
          margin-left: 9px;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: var(--color-Cornflower-Blue);
        }

        &:hover {
          text-decoration: underline;
        }

        p {
          width: 100%;
          font-size: 14px;
          font-family: NanumSquareOTF_acEB;
          line-height: 35px;
          border-bottom: 1px solid #f0f0f0;
          @include text-ellipsis(1);
        }
      }
    }

    li + li {
      margin-top: 5px;
    }
  }
}