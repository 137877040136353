
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#AnnouncementBoardTable {
  margin-top: 20px;
  width: 100%;

  thead {
    height: 32px;
    background-color: var(--table-head-background-color);

    th {
      font-family: NanumSquareOTF_acB;
    }
  }

  tbody {
    td {
      height: 32px;
      border-bottom: 1px solid var(--color-Alto);
      cursor: pointer;
    }
  }

  th,
  td {
    font-size: 12px;
    text-align: center;
  }

  th:nth-of-type(2),
  td:nth-of-type(2) {
    padding-left: 10px;
    text-align: left;
  }
}