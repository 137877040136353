
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
@font-face {
  font-family: NanumSquareOTF_acR;
  src: url("assets/font/NanumSquareOTF_acR.otf"), url("assets/font/NanumSquareOTF_acR.ttf") format("truetype");
}

@font-face {
  font-family: NanumSquareOTF_acB;
  src: url("assets/font/NanumSquareOTF_acB.otf"), url("assets/font/NanumSquareOTF_acB.ttf") format("truetype");
}

@font-face {
  font-family: NanumSquareOTF_acEB;
  src: url("assets/font/NanumSquareOTF_acEB.otf"), url("assets/font/NanumSquareOTF_acEB.ttf") format("truetype");
}

@import "material";
@import "bootstrap";
@import "summernote";

* {
  font-family: NanumSquareOTF_acR;
}

body {
  min-width: 1280px;

  @include mobile {
    min-width: 100vw;
    width: 100vw;
    overflow-x: hidden;
  }
}

.pc {
  @include mobile {
    display: none
  }

  @include desktop {
    display: revert;
  }
}

.mo {
  @include mobile {
    display: revert;
  }

  @include desktop {
    display: none;
  }
}

*::-webkit-scrollbar-thumb {
  background-color: #eaeaea;
  border-radius: 2px;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-track-piece {
  background-color: transparent;
}

.ir_txt {
  font-size: 0 !important;
}

table {
  width: 100%;

  thead {
    background-color: var(--table-head-background-color);

    th {
      font-family: NanumSquareOTF_acEB;
      white-space: nowrap;
    }
  }

  td {
    border-bottom: 1px solid var(--color-Alto);
  }

  th,
  td {
    height: 32px;
    font-size: 12px;
    text-align: center;
  }
}
.toastui-editor-contents {
  word-break: break-word;

  p {
    font-size: 16px;
    font-family: NanumSquareOTF_acR;
  }

  h3,
  h4 {
    line-height: initial;
  }
}