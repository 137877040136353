
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#SignupSuccess {
    .box {
        padding: 38px 40px 26px 40px;
        width: 388px;
    }

    .btn_next {
        margin-top: 20px;
        width: 175px;
    }
}