
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#SignupTerms {
    padding: 46px 0 87px 0;
    min-height: calc(100vh - #{$header-height} );

    .box {
        padding: 38px 40px 26px 40px;
        width: 740px;

        .wrap_terms {
            .terms {
                &_header {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  height: 24px;

                   &_title {
                      font-size: 12px;
                      font-family: NanumSquareOTF_acB;
                   }
                }

                &_content {
                    padding: 10px;
                    width: 660px;
                    height: 140px;
                    font-size: 11px;
                    line-height: 18px;
                    border: 1px solid var(--color-Alto);
                    overflow-y: scroll;
                }
            }

            .terms+.terms {
                margin-top: 20px;
            }
        }

        .btn_checkbox {
            height: 24px;
            font-size: 14px;

            label {
                margin-left: 5px;
            }
        }

        .btn_all_check {
            margin: 20px 0 10px 0;
            text-align: right;
        }
    }
}