
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#ReporterArticleList {
  padding: 20px;
  min-width: 970px;

  table {
    margin-bottom: 30px;
    width: 100%;

    th:nth-of-type(2),
    td:nth-of-type(2) {
      padding-left: 10px;
      text-align: left;
    }
  }

  .btns {
    display: flex;
    justify-content: center;
    align-content: center;
    column-gap: 5px;
  }

  .btn_edit {
    width: 47px;
    height: 28px;
    background: url("../../../assets/image/common/btn-edit.svg") no-repeat center;
  }

  .btn_more {
    width: 91px;
    height: 28px;
    background: url("../../../assets/image/common/btn-article-more.svg") no-repeat;
  }

}