
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#SignupContainer {
    padding: 50px 0;
    background-color: rgba(240, 240, 240, 0.2);
}