
					@import "src/_variables.scss";
					@import "src/_mixins.scss";
					@import "src/_color.scss";
					@import "src/_function.scss";
					@import "src/_reset.scss";
					@import "src/_icons.scss";
				
#AdminSectionSettingPopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 25px 25px 25px;
  width: 550px;
  height: 90vh;
  overflow-x: hidden;
  overflow-y: scroll;
  border: 1px solid var(--color-Alto);
  background-color: #ffffff;
  z-index: 2;

  .head {
    position: sticky;
    top: 0;
    margin-bottom: 30px;
    padding-top: 25px;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-bottom: 1px solid var(--color-Alto);
    background-color: #ffffff;
  }

  .category {
    display: flex;
    align-content: center;

    label {
      width: 100px;
      font-size: 14px;
      font-family: NanumSquareOTF_acEB;
      line-height: 42px;
    }

    input {
      padding: 0 10px;
      width: calc(100% - 100px);
      height: 42px;
      font-size: 14px;
      border: 1px solid var(--color-Alto);
    }
  }

  .btn_add {
    margin-top: 15px;
  }
}